export default {
  useCustomProperties: false,
  colors: {
    background: '#ffffff',
    text: '#000',
    red: '#ed6941',
    green: '#00ff27',
    purple: '#8d7bf0',
    grey: '#cecca1',
    black: '#000',
  },
  background: {
    gradient: {
      red: 'linear-gradient( 90deg, #ed6941 0%, #ffffff 100% )',
      green: 'linear-gradient( 90deg, #00ff27 0%, #ffffff 100% )',
      purple: 'linear-gradient( 90deg, #8d7bf0 0%, #ffffff 100% )',
      grey: 'linear-gradient( 90deg, #cecca1 0%, #ffffff 100% )',
    },
  },
  fonts: {
    body: 'Telegraf, Helvetica, Arial, sans-serif',
  },
  fontWeights: {
    body: 400,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.4,
  },
  fontSizes: [12, 15, 18, 20, 24, 30, 38, 52, 80, 96, 64],
  sizes: {
    maxWidth: '1404px',
    maxWidthCentered: '850px',
  },
  responsive: {
    smallMax: '679px',
    small: '680px',
    mediumMax: '999px',
    medium: '1000px',
    largeMax: '1279px',
    large: '1280px',
    desktopMax: '1399px',
    desktop: '1400px',
  },
  breakpoints: ['680px', '1000px'],
  space: [10, 20, 30, 60, 90, 120, 180],
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      fontSize: 2,
      lineHeight: 'body',
    },
    h1: {
      fontSize: 6,
      fontWeight: 'body',
      lineHeight: 'heading',
    },
    h2: {
      fontSize: 6,
      fontWeight: 'body',
      lineHeight: 'heading',
    },
    h3: {
      fontSize: 3,
      fontWeight: 'body',
      lineHeight: 'heading',
    },
    h4: {
      fontSize: 2,
      fontWeight: 'body',
      lineHeight: 'heading',
    },
    h5: {
      fontSize: 1,
      fontWeight: 'body',
      lineHeight: 'heading',
    },
    h6: {
      fontSize: 0,
      fontWeight: 'body',
      lineHeight: 'heading',
    },
  },
}
